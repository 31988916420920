<template lang="pug">
om-modal.image-replace(
  :name="name"
  color="light"
  width="608"
  @beforeOpen="beforeOpen"
  :clickToClose="false"
)
  template(slot="modal-header")
    om-heading(h4 v-html="$t('imageManagerModals.replace.title')")
    .brand-modal-action-icon(@click.stop="hide(true)")
      span.cursor-pointer(title="close" aria-hidden="true") X
  template(slot="modal-body")
    .image-replace-content
      .row
        .col-auto.image-replace-option.cursor-pointer(@click.stop="doCurrentPage")
          .illustration(@click.stop="doCurrentPage")
            img(:src="require('@/assets/admin/svg/imageReplace/current.svg')")
          .title(@click.stop="doCurrentPage") {{ $t('imageManagerModals.replace.buttons.currentPage') }}
        .col-auto.image-replace-option.cursor-pointer(@click.stop="doAllPages")
          .illustration(@click.stop="doAllPages")
            img(:src="require('@/assets/admin/svg/imageReplace/all.svg')")
          .title(@click.stop="doAllPages") {{ $t('imageManagerModals.replace.buttons.allPages') }}
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import { track } from '@/services/xray';
  import { getContextProperties } from '@/services/userInteractionTracker/properties';

  const modalName = 'image-replace';

  export default {
    data: () => ({ name: modalName, currentImage: null, selectedImage: null }),
    computed: {
      ...mapState(['selectedPage']),
    },
    methods: {
      ...mapActions(['changeImageOnPages']),
      beforeOpen(event) {
        this.currentImage = event?.params?.currentImage ?? null;
        this.selectedImage = event?.params?.selectedImage ?? null;
      },
      hide(userTriggered = false) {
        this.currentImage = null;
        this.selectedImage = null;

        if (userTriggered) {
          this.trackChoice('close');
        }

        this.$modal.hide(this.name);
      },
      startReplace(type = 'currentPage') {
        const { currentImage, selectedImage } = this;
        this.trackChoice(type);
        this.changeImageOnPages({ type, imageData: { currentImage, selectedImage } });
        this.$bus.$emit('re-render-all-previews');
      },
      doCurrentPage() {
        this.startReplace();
        this.hide();
      },
      doAllPages() {
        track(modalName, { value: 'yes' });
        this.startReplace('allPages');
        this.hide();
      },
      trackChoice(change) {
        track('editor_click', {
          location: 'image_replace_modal',
          change,
          ...getContextProperties(),
        });
      },
    },
  };
</script>

<style lang="sass">
  .image-replace
    &.v--modal-overlay
      z-index: 10000001 !important
    .brand-modal-header
      text-align: center
    .brand-modal-body
      padding: 1.5rem 2.5rem 2.5rem
      display: flex
      justify-content: center
      align-items: center
    .brand-modal-footer
      display: none
    &-content
      .illustration
        max-width: 11.5rem
      .title
        font-weight: 500
        font-size: 1rem
        line-height: 1.2
      .row
        gap: 2.5rem
        flex-wrap: unset
        &::after
          display: unset
          clear: unset
          content: unset
    &-option
      display: flex
      align-items: center
      flex-direction: column
      flex-grow: 0
      gap: 1.5rem
      height: 11.25rem
      width: 16.25rem
      justify-content: center
      padding: 2rem 1.875rem
      box-shadow: 0px 4px 10px 0px #0000000A, 0px 4px 16px 0px #0000000A
      border-radius: 8px
      max-width: 15.5rem
      transition: all .25s
      &:hover
        box-shadow: 0px 4px 10px 0px #0000001A, 0px 4px 16px 0px #0000001A
</style>
